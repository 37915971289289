import { Select, Option } from "@material-tailwind/react";
import BarChart from "components/charts/BarChart";
import "./styles.css";
import Card from "components/card";

const ScanOverTime = () => {
  //   graph data
  const barChartDataDailyTraffic = [
    {
      name: "Devices",
      data: [10, 20, 30, 20, 10, 40, 70],
    },
  ];

  //   graph setup
  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "",
        backgroundColor: "#000000",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: "",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: ["PC", "AndroidOS", "PC", "PC", "AndroidOS", "iOS", "iOS"],
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#4318FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(67, 24, 255, 1)",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: "40px",
      },
    },
  };

  return (
    <div className="flex w-full flex-col">
      <Card extra="pb-7 p-[20px]">
        <div className="my-2 flex w-full flex-wrap items-center gap-4">
          {/* graph heading */}
          <h2 className="m-2 mr-auto whitespace-nowrap text-2xl font-bold">
            Scan Over Time
          </h2>
          <div className="filter-inputs flex flex-wrap items-center gap-4">
            {/* graph filters */}
            <div className="w-52">
              {/* timezone filter */}
              <Select
                label="Timezone"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Option>Material Tailwind HTML</Option>
                <Option>Material Tailwind React</Option>
                <Option>Material Tailwind Vue</Option>
                <Option>Material Tailwind Angular</Option>
                <Option>Material Tailwind Svelte</Option>
              </Select>
            </div>
            <div className="w-52">
              {/* Groupe by filter */}
              <Select
                label="Group by"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Option>Material Tailwind HTML</Option>
                <Option>Material Tailwind React</Option>
                <Option>Material Tailwind Vue</Option>
                <Option>Material Tailwind Angular</Option>
                <Option>Material Tailwind Svelte</Option>
              </Select>
            </div>
          </div>
        </div>

        <div className="h-[300px] w-full pb-0 pt-10">
          {/* Graph */}
          <BarChart
            chartData={barChartDataDailyTraffic}
            chartOptions={barChartOptionsDailyTraffic}
          />
        </div>
      </Card>
    </div>
  );
};

export default ScanOverTime;
