import { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { useAuth } from "contexts/AuthProvider";
import Pagination from "components/pagination";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "configs/firebase";

const app = initializeApp(firebaseConfig);
const firebaseFunc = getFunctions(app);

interface QrCode {
  qrImage: string;
  createdAt: string;
  scans: number;
  qrName: string;
}

const QrCodeDatatable = () => {
  interface QrData {
    qrImage: string;
    createdAt: string;
    scans: number;
    campaign: string;
    url: string;
    id: string;
  }

  const [data, setData] = useState<QrData[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );
  const [previousPageToken, setPreviousPageToken] = useState<
    string | undefined
  >(undefined);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [totalRecord, setTotalRecord] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [direction, setDirection] = useState<"prev" | "next" | undefined>(
    undefined
  );
  const [loader, setLoader] = useState<boolean>(false);
  const { userInfo, currentUser } = useAuth();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isPagination, setIsPagination] = useState<boolean>(false);
  const [isPremium, setIsPremium] = useState<boolean>(true);

  let userAccountId = userInfo.userId;

  const getData = async () => {
    try {
      setLoader(true);
      // if (
      //   userInfo.subscriptionInfo.packageName !== "Adaptix Engage" &&
      //   userInfo.subscriptionInfo.packageName !== "Trial"
      // ) {
      //   setIsPremium(true);
      // }
      const url =
        process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL + "listQrCode";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser?.accessToken,
        },
        body: JSON.stringify({
          nextPageToken,
          previousPageToken,
          direction,
          userAccountId,
        }),
      });
      const response = await res.json();

      const data = response.data.map((d: any) => {
        return {
          qrImage: d.qrImage,
          createdAt: new Date(d.createdAt).toISOString().substr(0, 10),
          scans: d.scans,
          campaign: d.qrId,
        };
      });

      if (data.length == 0) {
        setIsEmpty(true);
      } else {
        const table_direction = direction != undefined ? direction : "next";
        const start =
          table_direction === "next" ? response.nextPageToken : undefined;
        const end =
          table_direction === "prev" ? response.previousPageToken : undefined;
        setTotalRecord(() => response.total);
        setTotalPages(() => response.perPage);
        setNextPageToken(start);
        setPreviousPageToken(end);
        setData(data);
        setLoader(false);
        setIsPagination(true);
        setIsEmpty(false);
      }
    } catch (e) {
      setHasError(!!e);
      setLoader(false);
      console.error(e);
    }
  };

  //   try {
  //     setLoader(true);

  //     // Get a reference to the Cloud Function
  //     const listQrCode = httpsCallable(firebaseFunc, "listQrCode");

  //     // Call the Cloud Function with data
  //     const result = (await listQrCode({
  //       nextPageToken,
  //       previousPageToken,
  //       direction,
  //       userAccountId,
  //     })) as {
  //       data: QrCode[];
  //       nextPageToken?: string;
  //       previousPageToken?: string;
  //       total?: number;
  //       perPage?: number;
  //     };

  //     const responseData: QrCode[] = Array.isArray(result.data)
  //       ? result.data
  //       : [];

  //     const data: any = responseData.map((d) => ({
  //       qrImage: d.qrImage,
  //       createdAt: new Date(d.createdAt).toISOString().substr(0, 10),
  //       scans: d.scans,
  //       campaign: d.qrName,
  //     }));

  //     if (data.length === 0) {
  //       setIsEmpty(true);
  //     } else {
  //       const table_direction = direction !== undefined ? direction : "next";
  //       const start =
  //         table_direction === "next" ? result.nextPageToken : undefined;
  //       const end =
  //         table_direction === "prev" ? result.previousPageToken : undefined;

  //       setTotalRecord(result.total);
  //       setTotalPages(result.perPage);
  //       setNextPageToken(start);
  //       setPreviousPageToken(end);
  //       setData(data);
  //       setLoader(false);
  //       setIsPagination(true);
  //       setIsEmpty(false);
  //     }
  //   } catch (error) {
  //     setHasError(true);
  //     setLoader(false);
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    getData();
  }, [currentPage]);

  // Handle pagination button clicks
  const funcPreviousClick = () => {
    setLoader(true);
    if (currentPage === 1) return;
    setDirection("prev");
    setCurrentPage((prev) => prev - 1);
  };

  const funcNextClick = () => {
    setLoader(true);
    if (currentPage === totalPages) return;
    setDirection("next");
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <div className="w-full">
      <div className="pagination relative h-[100vh]   dark:bg-gray-800">
        <table className=" xl:text-x  w-full pt-2 text-left text-base text-gray-500 dark:text-gray-400 rtl:text-right">
          <thead className="   border-b-2 border-b-gray-300 text-xs  text-gray-500 dark:bg-gray-700">
            <tr>
              <th
                scope="col"
                className="xl:text-x whitespace-nowrap px-2 py-3  text-base font-thin tracking-normal lg:px-12"
              >
                Campaign
              </th>
              <th
                scope="col"
                className="xl:text-x whitespace-nowrap px-2 py-4  text-base font-thin lg:px-12"
              >
                QR Code
              </th>
              <th
                scope="col"
                className="xl:text-x whitespace-nowrap px-2 py-4  text-base font-thin lg:px-12"
              >
                Created on
              </th>
              <th
                scope="col"
                className="xl:text-x whitespace-nowrap px-2 py-4   text-base font-thin lg:px-12"
              >
                Total Scans
              </th>
            </tr>
          </thead>
          <tbody>
            <>
              {loader && !isEmpty && (
                <tr>
                  <td
                    role="status"
                    className="  absolute flex h-full w-full items-center justify-center bg-gray-600 opacity-40"
                  >
                    <svg
                      aria-hidden="true"
                      className="inline h-[5rem] w-[5rem] animate-spin fill-blueSecondary text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </td>
                </tr>
              )}
              {!isEmpty ? (
                <>
                  <tr>
                    <th> </th>
                    <td colSpan={5} className="pt-4"></td>
                  </tr>
                  {data.map((e, index) => (
                    <tr
                      key={index}
                      className="dark:bg -gray-800 w-[350px] border-b bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <th className="w-32p whitespace-nowrap px-2 py-2 text-gray-900 dark:text-white   lg:px-12">
                        {isPremium ? (
                          <Link
                            to={`${e.campaign}`}
                            className=" xl:text-x text-base font-extrabold text-blueSecondary dark:hover:text-brandLinear dark:hover:opacity-80"
                          >
                            Campaign {e.campaign}
                          </Link>
                        ) : (
                          <span className=" xl:text-x text-base font-extrabold text-blueSecondary dark:hover:text-brandLinear dark:hover:opacity-80">
                            Campaign {e.campaign}
                          </span>
                        )}
                      </th>
                      <td className="flex justify-center px-2 py-3 text-gray-900 lg:px-12">
                        <img src={e.qrImage} alt="qr-code" className="h-10" />
                      </td>
                      <td className="whitespace-nowrap px-2 py-3 text-gray-900 dark:text-white lg:px-12">
                        {e.createdAt}
                      </td>
                      <td className="px-2 py-3 text-center text-gray-900 dark:text-white lg:px-12">
                        {e.scans}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={5} className="pt-12">
                    <div className="terminal-loader ">
                      <span className="text_message text-center  text-gray-600 ">
                        No campaigns created.<br /> Click on "Create new" to create one.
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </table>
        <div className="h-6 w-full"></div>
        {isPagination && !isEmpty && (
          <Pagination
            page={currentPage}
            pages={totalPages}
            total={totalRecord}
            previousLoader={loader}
            nextLoader={loader}
            handlePreviousClick={funcPreviousClick}
            handleNextClick={funcNextClick}
          />
        )}
      </div>
    </div>
  );
};

export default QrCodeDatatable;
