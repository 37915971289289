import { auth, db } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";

export const doCreateUserWithEmailAndPassword = async (
  email: string,
  password: string,
  name: string
) => {
  await createUserWithEmailAndPassword(auth, email, password).then(
    async (userCerdential) => {
      const authUser = userCerdential.user;
      await sendEmailVerification(authUser);
      //set user info
      const user = {
        userId: authUser.uid,
        name: name,
        phoneNumber: "",
        email: authUser.email,
        emailVerified: false,
        createdAt: new Date(),
        subscriptionInfo: {},
      };
      // store user in the firestore db
      const docRef = await addDoc(collection(db, "users"), user);
    }
  );
};

export const doSignInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  return await signInWithEmailAndPassword(auth, email, password);
};
export const doSignInWithEmailAndPasswordPersistence = async (
  email: string,
  password: string
) => {
  await setPersistence(auth, browserSessionPersistence);
  // Existing and future Auth states are now persisted in the current
  // session only. Closing the window would clear any existing state even
  // if a user forgets to sign out.
  // ...
  // New sign-in will be persisted with session persistence.
  return await signInWithEmailAndPassword(auth, email, password);
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;
  // add user to firestore
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password: string) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};
