import React from "react";
import {
  LinkIcon,
} from "@chakra-ui/icons";
import { MdDashboard } from "react-icons/md";

interface CampaignSummaryProps {
  qrImage: string;
  url: string;
  shortUrl: string
  createdAt: string;
  campaign: string;
  topLocation: string;
  topDevice: string;
  totalScans: number;
  uniqueScans: number;
}

// function getSiteName(url: string) {
//   // Remove protocol
//   let siteName = url.replace(/(^\w+:|^)\/\//, "");
//   // Remove www
//   siteName = siteName.replace("www.", "");
//   // Extract domain name
//   siteName = siteName.split("/")[0];
//   // Split .com
//   const parts = siteName.split(".");
//   // Remove the last part (.com)
//   parts.pop();
//   // Join remaining parts
//   siteName = parts.join(".");
//   // Capitalize first letter
//   siteName = siteName.charAt(0).toUpperCase() + siteName.slice(1);
//   return siteName;
// }

const downloadImage = () => {
  try {
    const imageUrl = document.getElementById("QrImage")?.getAttribute("src");

    if (imageUrl) {
      // Fetch the image and create a download link
      fetch(imageUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "image.png");
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((err) => {
          console.log("Error fetching image:", err);
        });
    } else {
      console.error("Image URL is empty");
    }
  } catch (error) {
    console.error("Error downloading image:", error);
  }
};





const CampaignSummary: React.FC<CampaignSummaryProps> = ({
  qrImage,
  url,
  shortUrl,
  createdAt,
  campaign,
  topLocation,
  topDevice,
  totalScans,
  uniqueScans,
}) => {


  const date = new Date(createdAt);

  // Extract the month, day, and year from the date object
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  
  // Format the date in the desired format
  const formattedDate = `${month + 1}/${day}/${year}`;

  // const siteName = getSiteName(url);

  return (
    <div className="w-full rounded-lg px-6 py-7">
      <h1 className="mb-4 mt-8 text-2xl font-bold dark:text-white">
        <strong></strong> {campaign}
      </h1>

      <div className="mt-5 flex w-full flex-wrap items-center justify-evenly gap-2 3xl:flex-nowrap">
        <div className="flex flex-col justify-between">
          <div className="flex w-full items-center justify-center p-4 gap-4 flex-wrap">
            <img
              src={qrImage}
              id="QrImage"
              alt="QR Code"
              className="mb-4 h-40 w-40"
            />
            <div>
              <p className="mb-2 flex flex-col text-gray-800 dark:text-white">
                {/* <strong className="text-2xl">{siteName}</strong> */}
                <strong>URL</strong>
              </p>
              <p className="mb-2 flex gap-1 text-gray-800 dark:text-white">
                <LinkIcon w={20} h={20} className="mr-1" /> {shortUrl}
              </p>
              <p className="mb-2 flex gap-1 text-gray-800 dark:text-white">
                <strong>Created At: </strong> {formattedDate}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center p-4">
          <div className="flex justify-center gap-8">
            <p className="mb-2 flex flex-col items-center gap-1 text-gray-800 dark:text-white">
              <strong>Total Scans</strong>
              <span className="flex items-center gap-1">
                <MdDashboard /> {totalScans}
              </span>
            </p>
            <p className="mb-2 flex flex-col items-center gap-1 text-gray-800 dark:text-white">
              <strong>Unique Scans</strong>
              <span className="flex items-center gap-1">
                <MdDashboard /> {uniqueScans}
              </span>
            </p>
          </div>
          {/* <div className="flex w-52 flex-wrap gap-1">
            <button className="flex items-center justify-center rounded-lg border-[1px] border-gray-700 p-3 px-6">
              <RepeatIcon />
            </button>
            <button className="flex items-center justify-center rounded-lg border-[1px] border-gray-700 p-3 px-6">
              <TimeIcon />
            </button>
            <button className="flex items-center justify-center rounded-lg border-[1px] border-gray-700 p-3 px-6">
              <BellIcon />
            </button>
            <button className="flex items-center justify-center rounded-lg border-[1px] border-gray-700 p-3 px-6">
              <LockIcon />
            </button>
            <button className="flex items-center justify-center rounded-lg border-[1px] border-gray-700 p-3 px-6">
              <MdAddLocation />
            </button>
            <button className="flex items-center justify-center rounded-lg border-[1px] border-gray-700 p-3 px-6">
              <MdLocationCity />
            </button>
          </div> */}
        </div>
        <div className="item-center flex min-w-fit flex-col justify-center gap-4 p-4">
          <div className="flex w-full justify-center gap-8">
            <p className="mb-2 flex flex-col gap-1 text-gray-800 dark:text-white">
              Top Location
              <strong>{topLocation ? topLocation : 'No data'}</strong>
            </p>
            <p className="mb-2 flex flex-col gap-1 text-gray-800 dark:text-white">
              Top Device
              <strong>{topDevice ? topDevice : 'No data'}</strong>
            </p>
          </div>
          <div className="flex flex-wrap gap-3 xl:flex-nowrap justify-center">
            {/* <button className="rounded-xl bg-brand-500 px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Edit
            </button> */}
            <button
              className="rounded-xl bg-green-500 px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
              onClick={downloadImage}
            >
              Download Image 
            </button>
            {/* <button className="whitespace-nowrap rounded-xl bg-yellow-500 px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-yellow-600 active:bg-yellow-700 dark:bg-yellow-400 dark:text-white dark:hover:bg-yellow-300 dark:active:bg-yellow-200">
              Download CSV
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSummary;
