import { useAuth } from "contexts/AuthProvider";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Confirmation = () => {
  const { userInfo, currentUser, setShowImage } = useAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true); // Confirmation loading state

  // reading paymant data from Hubspot
  useEffect(() => {
    setShowImage(true); // setup the image at right side of form
    callFirebaseFunction();
  }, []);

  // getting payment data from Hubspot by firebase cloude functions
  const callFirebaseFunction = async () => {
    const url = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL + "payments";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.accessToken,
      },
      body: JSON.stringify({
        email: userInfo.email,
        userId: userInfo.userId,
      }),
    });
    const temp = await res.json();
    setLoading(false);
    // check if payment success or not and redirect
    if (temp.total <= 0) {
      navigate("/admin/price");
    }
  };

  return (
    <div className="confirm-wrapper flex h-full items-start">
      <div className="bg">
        {/* Confirmation loading spinner */}
        {!loading ? (
          <>
            <div className="card">
              {/* confirmation success card */}
              <span className="card__success flex items-center justify-center text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  height="72"
                  width="72"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <path d="M474.045,173.813c-4.201,1.371-6.494,5.888-5.123,10.088c7.571,23.199,11.411,47.457,11.411,72.1   c0,62.014-24.149,120.315-68,164.166s-102.153,68-164.167,68s-120.316-24.149-164.167-68S16,318.014,16,256   S40.149,135.684,84,91.833s102.153-68,164.167-68c32.889,0,64.668,6.734,94.455,20.017c28.781,12.834,54.287,31.108,75.81,54.315   c3.004,3.239,8.066,3.431,11.306,0.425c3.24-3.004,3.43-8.065,0.426-11.306c-23-24.799-50.26-44.328-81.024-58.047   C317.287,15.035,283.316,7.833,248.167,7.833c-66.288,0-128.608,25.813-175.48,72.687C25.814,127.392,0,189.712,0,256   c0,66.287,25.814,128.607,72.687,175.479c46.872,46.873,109.192,72.687,175.48,72.687s128.608-25.813,175.48-72.687   c46.873-46.872,72.687-109.192,72.687-175.479c0-26.332-4.105-52.26-12.201-77.064   C482.762,174.736,478.245,172.445,474.045,173.813z" />
                    <path d="M504.969,83.262c-4.532-4.538-10.563-7.037-16.98-7.037s-12.448,2.499-16.978,7.034l-7.161,7.161   c-3.124,3.124-3.124,8.189,0,11.313c3.124,3.123,8.19,3.124,11.314-0.001l7.164-7.164c1.51-1.512,3.52-2.344,5.66-2.344   s4.15,0.832,5.664,2.348c1.514,1.514,2.348,3.524,2.348,5.663s-0.834,4.149-2.348,5.663L217.802,381.75   c-1.51,1.512-3.52,2.344-5.66,2.344s-4.15-0.832-5.664-2.348L98.747,274.015c-1.514-1.514-2.348-3.524-2.348-5.663   c0-2.138,0.834-4.149,2.351-5.667c1.51-1.512,3.52-2.344,5.66-2.344s4.15,0.832,5.664,2.348l96.411,96.411   c1.5,1.5,3.535,2.343,5.657,2.343s4.157-0.843,5.657-2.343l234.849-234.849c3.125-3.125,3.125-8.189,0-11.314   c-3.124-3.123-8.189-3.123-11.313,0L212.142,342.129l-90.75-90.751c-4.533-4.538-10.563-7.037-16.98-7.037   s-12.448,2.499-16.978,7.034c-4.536,4.536-7.034,10.565-7.034,16.977c0,6.412,2.498,12.441,7.034,16.978l107.728,107.728   c4.532,4.538,10.563,7.037,16.98,7.037c6.417,0,12.448-2.499,16.977-7.033l275.847-275.848c4.536-4.536,7.034-10.565,7.034-16.978   S509.502,87.794,504.969,83.262z" />
                  </g>
                </svg>
              </span>

              <h1 className="-mt-8 font-poppins text-3xl text-gray-800">
                Payment Complete
              </h1>
              <h2 className="text-md mt-2 font-poppins text-blueSecondary">
                Thank you for your transfer
              </h2>
            </div>
            <Link to="/admin">
              <button className="linear text-md mt-8 w-full rounded-xl bg-brand-500  px-5 py-[12px]  font-poppins font-medium tracking-wide	 text-white transition  duration-500 hover:bg-brand-600 hover:tracking-widest active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400	 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Dashboard
              </button>
            </Link>
          </>
        ) : (
          <span role="status">
            {/* confirmations loading section */}
            <svg
              aria-hidden="true"
              className="inline h-20 w-20 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
