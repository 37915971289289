import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "./components/Storage";
import Upload from "./components/Upload";
import { useAuth } from "../../../contexts/AuthProvider";
import { Navigate } from "react-router-dom";
import PricePage from "views/price/PricePage";

const ProfileOverview = () => {
  const { userLoggedIn, userInfo } = useAuth();
  if (!userLoggedIn) {
    return (
      <div>
        <Navigate to={"/auth/sign-in"} replace={true} />
      </div>
    );
  }
  return (
    <>
      <div className="flex w-full flex-col">
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <Banner
            name={userInfo?.name ? userInfo.name : "Guest User"}
            email={userInfo?.email ? userInfo.email : "test@gmail.com"}
          />
          <Storage />
        </div>
        <div className="grid grid-cols-1">
          <PricePage />
        </div>
      </div>
    </>
  );
};

export default ProfileOverview;
