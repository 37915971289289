import InputField from "components/fields/InputField";
import { doCreateUserWithEmailAndPassword } from "../../configs/auth";
import { useAuth } from "../../contexts/AuthProvider";
import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  validateEmail,
  validateName,
  validatePassword,
} from "utils/validators";

export default function SignUp() {
  const navigate = useNavigate();
  const { userLoggedIn, setShowImage } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signUpSuccess, setSignUpSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // Sign In button loading state



  useEffect(() => {
    setShowImage(true); // setup the image at right side of form
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = e.target.value;
    setName(nameValue);
    setNameError(validateName(nameValue) || "");
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError(validateEmail(emailValue) || "");
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setPasswordError(validatePassword(passwordValue) || "");
  };

  const onSubmit = async () => {
    setError("");
    setLoading(true);
    try {
      await doCreateUserWithEmailAndPassword(email, password, name);
      setLoading(false);
      setSignUpSuccess(true);

      // Handle successful sign-in
    } catch (error) {
      setLoading(false); // back to false if sign in failed
      if (error instanceof Error) {
        setError(error.message); // Error message
      } else {
        console.error("Unknown error occurred:", error);
      }
    }
  };

  const handleKeyPress = (e :any)=> {
    if(e.key === 'Enter'){
      if(!nameError && !passwordError && !emailError && !error){
        onSubmit();
      }
    }
  }


  




  return (
    <>
      <div className="mb-10 mt-16 flex h-full w-full  flex-col items-start justify-center px-2 md:mx-0 md:px-0 lg:mb-10  lg:justify-start">
        <h4 className="mb-2.5 self-start text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <div
          className={
            !signUpSuccess
              ? "mb-[5vh] mr-8 mt-5 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[450px]"
              : "hidden"
          }
        >
          <p className="mb-9 ml-1 text-base text-gray-600">
            Signup for 14 days free trial. Upgrade anytime. 
          </p>
          <InputField
            variant="name"
            extra="mb-3"
            label="Name*"
            placeholder="Joe"
            id="name"
            type="text"
            value={name}
            onChange={handleNameChange}
            onBlur={() => setNameError(validateName(name))}
            onKeyPress={(e) => handleKeyPress(e)}

          />
          {nameError ? (
            <div className=" mb-3  ml-3 h-4 text-xs text-red-500">
              {nameError}
            </div>
          ) : (
            <div className=" mb-3  ml-3 h-4 text-xs text-red-500 opacity-0">
              correct
            </div>
          )}

          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            value={email}
            onChange={handleEmailChange}
            onBlur={() => {
              setError("");
              setEmailError(validateEmail(email));
            }}
            onKeyPress={handleKeyPress}
          />

          {error === "Firebase: Error (auth/email-already-in-use)." && (
            <div className="mb-3 ml-3 h-4 text-xs text-red-500">
              Please provide another email, this one is already in use.
            </div>
          )}

          {emailError && (
            <div className="mb-3 ml-3 h-4 text-xs text-red-500">
              {emailError}
            </div>
          )}

          {!emailError && (
            <div className="mb-3 ml-3 h-4 text-xs text-red-500 opacity-0">
              Correct
            </div>
          )}

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onBlur={() => setPasswordError(validatePassword(password))}
            onKeyPress={handleKeyPress}
            
            
          />
          {passwordError ? (
            <div className="mb-7 ml-3 h-5 text-xs text-red-500">
              {passwordError}
            </div>
          ) : (
            <div className="mb-7 ml-3 h-5 text-xs text-red-500 opacity-0"></div>
          )}
          <button
            disabled={
              !name ||
              !email ||
              !password ||
              !!emailError ||
              !!nameError ||
              !!passwordError
                ? true
                : false
            }
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={onSubmit} onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
            
          >
            {!loading ? (
              <span>Create a free account</span>
            ) : (
              <span role="status">
                {/* loading spinner when user click Sign in */}
                <svg
                  aria-hidden="true"
                  className="inline h-5 w-5 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </span>
            )}
          </button>
          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Already registered?
            </span>
            <Link
              to="/auth/sign-in"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            >
              Login
            </Link>
          </div>
        </div>

        <div
          className={
            signUpSuccess
              ? "mb-[5vh] mr-8 mt-[10vh]  w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[450px]"
              : "hidden"
          }
        >
          <p className="mb-9 ml-1 text-base text-green-500">
            Please check your email inbox for Verification email sent by us. You will be able to login after your email is verified. 
          </p>

          <Link to="/auth/sign-in">
            <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400	 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Login
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
