import React from "react";

interface PaginationProps {
  page: number;
  pages: number;
  total: number;
  previousLoader: boolean;
  nextLoader: boolean;
  handlePreviousClick: () => void;
  handleNextClick: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  pages,
  total,
  previousLoader,
  nextLoader,
  handlePreviousClick,
  handleNextClick,
}) => {
  return (
    <div className="border-xl ml-auto mr-auto mt-2 flex w-full items-center justify-start space-x-2 rounded-xl border-gray-300  dark:bg-navy-800 ">
      <div className="ml-auto mr-auto inline-flex gap-2 -space-x-px text-sm">
        <button
          disabled={page === 1 || previousLoader}
          onClick={handlePreviousClick}
          className={
            page !== 1
              ? "linear  rounded-xl bg-brand-500  px-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : " linear  rounded-xl  px-4 text-base font-medium text-white opacity-0 "
          }
        >
          Previous
        </button>
        <div className=" flex items-center justify-center bg-white leading-tight text-gray-500 dark:bg-navy-800 dark:text-gray-400 ">
          <span className="ml-2  mr-2 text-blueSecondary dark:text-white">
            {pages * page - (pages - 1)}-
            {page === pages
              ? (total % pages) + pages * page - pages
              : page * pages}{" "}
            of {total}
          </span>
        </div>
        <button
          onClick={handleNextClick}
          disabled={page === pages || nextLoader}
          className={
            page !== pages
              ? "linear  rounded-xl bg-brand-500  p-2 px-8 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear    p-2 px-8 text-base font-medium text-white opacity-0 "
          }
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
