import * as Joi from "joi";

const nameScheme = Joi.string()
  .pattern(/^[^0-9]/)
  .min(3)
  .max(30)
  .required()
  .messages({
    "string.min": `name must be at lease 3 characters.`,
    "string.max": `name can be at maximum 30 characters.`,
    "string.empty": `Please enter a name.`,
    "any.required": `Name is required.`,
    "string.pattern.base": `Name cannot start with a number`,
  });

export function validateName(inputValue: string): string | null {
  const { error } = nameScheme.validate(inputValue);
  if (error) {
    return error.details.map((detail) => detail.message).join(", ");
  }
  return null;
}

const emailSchema = Joi.string()
  .pattern(/^[^0-9]/)
  .email({
    minDomainSegments: 2,
    tlds: { allow: false },
  })
  .messages({
    "email.empty": `Email cannot be empty`,
    "any.required": `Email is required`,
    "string.pattern.base": `Email cannot start with a number`,
  });

export function validateEmail(inputValue: string) {
  const { error } = emailSchema.validate(inputValue, { abortEarly: false });
  if (error) {
    return error.details
      .map((detail) => {
        if (detail.type === "string.empty") {
          return "Email cannot be empty";
        } else if (detail.type === "string.email") {
          return "Email must be a valid email";
        }
        return detail.message;
      })
      .join(", ");
  }
  return null;
}

const passwordScheme = Joi.string()
  .pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{8,16}$/)
  .required()
  .messages({
    "string.pattern.base": `Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, and no space. It must be 8-16 characters long.`,
    "string.empty": `Password cannot be empty`,
    "any.required": `Password is required`,
  });

export function validatePassword(inputValue: string): string | null {
  const { error } = passwordScheme.validate(inputValue);
  if (error) {
    return error.details.map((detail) => detail.message).join(", ");
  }
  return null;
}

const passwordSchemeSignIn = Joi.string().required().messages({
  "string.empty": `Password cannot be empty`,
});

export function validatePasswordSignIn(inputValue: string): string | null {
  const { error } = passwordSchemeSignIn.validate(inputValue);
  if (error) {
    return error.details.map((detail) => detail.message).join(", ");
  }
  return null;
}
