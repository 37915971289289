import InputField from "components/fields/InputField";
import "./style.css";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthProvider";
import { Tab } from "@headlessui/react";
import KanbanIcon from "components/icons/KanbanIcon";
import QrCodeDatatable from "components/qrCodeTable";
import DataTableIcon from "components/icons/DatatableIcon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowBackIcon } from "@chakra-ui/icons";

const QRCode = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isFileExist, setIsFileExist] = useState<boolean>(false);
  const { userInfo, currentUser } = useAuth();
  const [inputError, setInputError] = useState<string>("");
  const [selectedNumber, setSelectedNumber] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [generatedQrCode, setGenerateQrCode] = useState<boolean>(false);
  useEffect(() => {
    setInputValue("");
    setIsFileExist(false);
    setInputError("");
  }, [generatedQrCode]);
  useEffect(() => {
    setInputValue("");
    setLoader(true);
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    if (selectedNumber === 1) {
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        // @ts-ignore
        if (window.hbspt) {
          // @ts-ignore
          window.hbspt.forms?.create({
            portalId: "21403389",
            formId: "8b8c6978-9e0e-412b-88e6-6ee6e158a408",
            target: "#fileUpload",
          });
        }
      });
    } else {
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        // @ts-ignore
        if (window.hbspt) {
          // @ts-ignore
          window.hbspt.forms?.create({
            portalId: "21403389",
            formId: "e05bd054-ec58-4903-9f80-927b1e12582d",
            target: "#v-card",
          });
        }
      });
    }
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, [selectedNumber]);

  const handleGenerateQRCode = async () => {
    // Format the date in the desired format
    const nowDate = new Date().getTime();
    const expDate = new Date(userInfo.subscriptionInfo.endDate).getTime();
    console.log(userInfo);
    console.log("nowDate <= expDate", nowDate <= expDate);
    if (inputValue) {
      if (userInfo.subscriptionInfo.availableCredits > 0) {
        const qrCodeContainer = document.getElementById("qrCodeContainer");
        qrCodeContainer.innerHTML = "";
        await generateQRCode(inputValue);
      } else {
        toast.error(
          "You don't have any credits remaining. Upgrade your package.",
          {
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: `${
              document.body.classList.contains("dark") ? "dark" : "light"
            }`,
          }
        );
      }
    } else {
      setInputError("Please Enter a Url.");
      setIsLoader(false);
    }
  };

  // Toast
  const notify = (text: any) => toast(text);

  // Function to generate QR code and save data to Firestore
  async function generateQRCode(data: string) {
    console.log(currentUser);
    console.log("qr code data", data);
    setIsLoader(true);
    // API endpoint for generating QR code

    try {
      // Make a fetch request to generate the QR code
      const url =
        process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL + "generateQrCode";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser?.accessToken,
        },
        body: JSON.stringify({
          url: data,
          userName: userInfo.name,
          userId: userInfo.userId,
        }),
      });
      const responseData = await response.json();
      console.log(responseData);
      const imageUrl = responseData.imageUrl;

      if (imageUrl) {
        // Create an img element for the QR code
        const qrCodeImg = document.createElement("img");
        qrCodeImg.src = imageUrl;
        qrCodeImg.style.width = "200px";
        qrCodeImg.alt = "QR Code";
        qrCodeImg.id = "QrImage";
        setIsLoader(false);

        // Qrcode successfully created
        toast.success("Action successfully created", {
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: `${
            document.body.classList.contains("dark") ? "dark" : "light"
          }`,
        });

        // Append the QR code image to the container
        const qrCodeContainer = document.getElementById("qrCodeContainer");
        qrCodeContainer?.appendChild(qrCodeImg);
        // setInputValue("");
        setIsFileExist(true);
      } else {
        console.error("Image URL is empty");
        setIsFileExist(false);
        setIsLoader(false);
        // QR Code was not created!
        toast.error("Action was not created!", {
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: `${
            document.body.classList.contains("dark") ? "dark" : "light"
          }`,
        });
      }
    } catch (error) {
      console.log("Error fetching QR code image:", error);
      setIsLoader(false);
      // QR Code was not created!
      toast.error("Action was not created!", {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: `${document.body.classList.contains("dark") ? "dark" : "light"}`,
      });
      setInputError(error.toString());
      // setInputValue("");
    }
  }
  // Function to download the QR code image
  const downloadImage = () => {
    try {
      const imageUrl = document.getElementById("QrImage")?.getAttribute("src");

      if (imageUrl) {
        // Fetch the image and create a download link
        fetch(imageUrl)
          .then((response) => {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "image.png");
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch((err) => {
            console.log("Error fetching image:", err);
          });
      } else {
        console.error("Image URL is empty");
      }
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  // Function to handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError("");
    setInputValue(" ");
    let newValue = e.target.value.trim();

    // Check if the input value starts with "https://"
    if (!newValue.startsWith("https://")) {
      newValue = "https://" + newValue;
    }

    setInputValue(newValue);
  };
  return (
    <div
      className={
        generatedQrCode
          ? " mt-5 flex h-[full] w-full "
          : " -ml-2 mt-5 flex h-[full] w-full xl:ml-0"
      }
    >
      <ToastContainer />
      {generatedQrCode && (
        <button
          onClick={() => setGenerateQrCode(false)}
          type="button"
          className="linear text-md absolute m-2 shrink-0 rounded-lg bg-brand-500 px-3 py-1.5 font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <ArrowBackIcon w={20} h={20} color={"white"} /> Back
        </button>
      )}
      <div
        className={
          isLoader
            ? "relative ml-auto mr-auto min-h-[100vh]  rounded-lg  bg-white dark:bg-gray-800 md:block"
            : "relative ml-auto mr-auto min-h-[100vh]  rounded-lg bg-white pb-8 dark:bg-gray-800 md:block"
        }
      >
        <div className="flex w-full flex-col items-center justify-between md:mx-0 md:px-0 ">
          {generatedQrCode ? (
            <Tab.Group defaultIndex={0} manual>
              <Tab.List className="tab-container">
                <Tab
                  className={
                    selectedNumber === 0
                      ? "tab-selected flex items-center gap-2 dark:bg-gray-800  dark:text-white"
                      : "   tab regular-tab flex items-center gap-2 dark:bg-white "
                  }
                  onClick={() => setSelectedNumber(0)}
                >
                  <KanbanIcon />
                  <span className="lg:text-x  shrink-0 text-base font-semibold">
                    Actions
                  </span>
                </Tab>
                <Tab
                  className={
                    selectedNumber === 1
                      ? "tab-selected  flex items-center gap-2 dark:bg-gray-800 dark:text-white "
                      : "   tab regular-tab group flex items-center gap-2 dark:bg-white"
                  }
                  onClick={() => setSelectedNumber(1)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 21 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="shrink-0"
                  >
                    <path
                      fill="currentColor"
                      d="M14.2503 0.083313H2.33366C1.14199 0.083313 0.166992 1.05831 0.166992 2.24998V16.3333C0.166992 16.9291 0.654492 17.4166 1.25033 17.4166C1.84616 17.4166 2.33366 16.9291 2.33366 16.3333V3.33331C2.33366 2.73748 2.82116 2.24998 3.41699 2.24998H14.2503C14.8462 2.24998 15.3337 1.76248 15.3337 1.16665C15.3337 0.570813 14.8462 0.083313 14.2503 0.083313ZM14.8895 5.05581L20.122 10.2883C20.5228 10.6891 20.7503 11.2416 20.7503 11.8158V21.75C20.7503 22.9416 19.7753 23.9166 18.5837 23.9166H6.65616C5.46449 23.9166 4.50033 22.9416 4.50033 21.75L4.51116 6.58331C4.51116 5.39165 5.47533 4.41665 6.66699 4.41665H13.3512C13.9253 4.41665 14.4778 4.64415 14.8895 5.05581ZM14.2503 12H19.1253L13.167 6.04165V10.9166C13.167 11.5125 13.6545 12 14.2503 12Z"
                      // className=" group-open:fill-white group-hover:fill-white"
                    />
                  </svg>
                  <span className="lg:text-x  shrink-0 text-base font-semibold">
                    File Upload
                  </span>
                </Tab>
                <Tab
                  className={
                    selectedNumber === 2
                      ? "tab-selected flex  items-center gap-2 dark:bg-gray-800 dark:text-white"
                      : "   tab regular-tab group flex items-center gap-2 dark:bg-white"
                  }
                  onClick={() => setSelectedNumber(2)}
                >
                  <svg
                    height="20"
                    width="20"
                    viewBox="0 0 24 24"
                    data-name="Layer 1"
                    className=" shrink-0"
                  >
                    <path
                      fill="currentColor"
                      className="group-hover:fill-white"
                      d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"
                    ></path>
                  </svg>
                  <span className="lg:text-x  shrink-0 text-base font-semibold">
                    V-Card
                  </span>
                </Tab>
              </Tab.List>
              <Tab.Panels className=" h-full w-full">
                <Tab.Panel>
                  <div className="mb-[5vh] ml-auto mr-auto mt-6  flex w-full   max-w-full flex-col items-center gap-2 rounded-lg bg-opacity-0 px-8 py-2    lg:max-w-[450px]  lg:items-center  ">
                    <h4 className="text-4lg mb-2.5 font-bold text-navy-700 dark:text-white">
                      Adaptix Actions
                    </h4>
                    {/* <p className="mb-5 ml-1 text-base text-gray-600">
                      Please Enter a valid URL.
                    </p> */}
                    <div className="flex h-[200px] w-full  items-center justify-center ">
                      <div
                        className={
                          isLoader || isFileExist
                            ? "shadow-2lg group flex h-[200px] w-[200px] items-center justify-center delay-300 ease-in-out  lg:items-center"
                            : "group flex h-[200px] w-[200px] items-center justify-center delay-300 ease-in-out lg:items-center"
                        }
                      >
                        <div
                          className={isLoader ? "loadingspinner " : "hidden"}
                        >
                          <div id="square1" className="dark:bg-white"></div>
                          <div id="square2" className="dark:bg-white"></div>
                          <div id="square3" className="dark:bg-white"></div>
                          <div id="square4" className="dark:bg-white"></div>
                          <div id="square5" className="dark:bg-white"></div>
                        </div>
                        <div
                          id="qrCodeContainer"
                          className={
                            isFileExist ? "hover:blur-[1px]" : "opacity-0"
                          }
                        ></div>
                        <button
                          onClick={() => downloadImage()}
                          className={
                            isFileExist
                              ? "absolute left-[45%] top-[45%] animate-bounce cursor-pointer rounded-md bg-brand-500 px-3 py-2 tracking-wider text-white shadow-lg group-hover:block"
                              : "hidden"
                          }
                        >
                          {isFileExist && (
                            <svg
                              className="h-5 w-5 "
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                              ></path>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>

                    <InputField
                      variant="URL"
                      extra="mb-3 w-full inputDark"
                      label="URL*"
                      placeholder="https://www.google.com/"
                      id="name"
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      disabled={isFileExist}
                    />

                    {inputError ? (
                      <div className="mb-4 ml-4 h-4 w-full  self-start text-wrap text-sm text-red-500">
                        {inputError}
                      </div>
                    ) : (
                      <div className="mb-4 ml-4 h-4 text-sm text-red-500 opacity-0"></div>
                    )}
                    <button
                      className="linear  rounded-lg bg-brand-500  px-7 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      onClick={handleGenerateQRCode}
                      disabled={isFileExist}
                    >
                      <span className="text ">Generate Action</span>
                    </button>

                    {isFileExist && (
                      <button
                      className="bg-white text-brand-500 text-sm"
                        onClick={() => {
                          setGenerateQrCode(!generatedQrCode);
                        }}
                      >
                        Reset & create new
                      </button>
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  {loader && (
                    <div
                      role="status"
                      className="absolute left-[50%]  top-[30%]"
                    >
                      <svg
                        aria-hidden="true"
                        className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <div
                    id="fileUpload"
                    className={
                      loader
                        ? " absolute opacity-0 "
                        : "mt-2   rounded-lg bg-white  p-2  px-6 dark:bg-white"
                    }
                  ></div>
                </Tab.Panel>
                <Tab.Panel>
                  {loader && (
                    <div
                      role="status"
                      className="absolute left-[50%] top-[30%]"
                    >
                      <svg
                        aria-hidden="true"
                        className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <div
                    id="v-card"
                    className={
                      loader
                        ? " absolute opacity-0"
                        : "mt-2    rounded-lg  p-2 px-6 dark:bg-white"
                    }
                  ></div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          ) : (
            <div className=" mb-4 mt-4  flex w-[90%] items-center justify-between">
              <p className="shrink-0 text-lg font-bold">Campaigns</p>
              <button
                className="linear mx-2 mt-2 shrink-0 rounded-lg bg-brand-500 px-6 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={() => {
                  setGenerateQrCode(!generatedQrCode);
                }}
              >
                Create new
              </button>
            </div>
          )}
          {!generatedQrCode && (
            <div>
              <QrCodeDatatable />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QRCode;
