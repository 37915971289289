import React, { useEffect, useContext, useState } from "react";
import { auth, db, firebaseConfig, app } from "../configs/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getDocs, collection, where, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userHasPackage, setUserHasPackage] = useState("");
  const [isEmailUser, setIsEmailUser] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      sessionStorage.setItem(
        "firebase:authUser:" + firebaseConfig.apiKey + ":" + app.name,
        localStorage.getItem("auth")
      );
      const unsubscribe = onAuthStateChanged(auth, initializeUser);
      return unsubscribe;
    } else {
      const unsubscribe = onAuthStateChanged(auth, initializeUser);
      return unsubscribe;
    }
    // dependency check when user data changes
  }, [auth.currentUser, isEmailVerified]);

  async function initializeUser(user) {
    if (user) {
      localStorage.setItem(
        "auth",
        sessionStorage.getItem(
          "firebase:authUser:" + firebaseConfig.apiKey + ":" + app.name
        )
      );
      // check user email verified
      if (user.emailVerified) {
        setCurrentUser({ ...user });
        const isEmail = user.providerData.some(
          (provider) => provider.providerId === "password"
        );
        setIsEmailUser(isEmail);
        setIsEmailVerified(true);
        // get auth user info
        const q = query(
          collection(db, "users"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);
        setUserLoggedIn(true);
        if (querySnapshot.docs.length > 0) {
          const userDoc = querySnapshot.docs[0];
          setUserInfo(userDoc.data());
          // setting package and go to route after getting from firebase
          setUserHasPackage(userDoc.data().subscriptionInfo.status);
          if (userDoc.data().subscriptionInfo.status === "Active") {
            if (!localStorage.getItem("default")) {
              localStorage.setItem("default", "yes");
              navigate("/admin");
            }
          } else {
            navigate("/auth/price");
          }
        }
      } else {
        setCurrentUser(null);
        setIsEmailUser(false);
        setUserLoggedIn(false);
        setIsEmailVerified(false);
      }
    } else {
      setCurrentUser(null);
      setIsEmailUser(false);
      setUserLoggedIn(false);
      setIsEmailVerified(false);
    }
    setLoading(false);
  }

  const value = {
    userLoggedIn,
    isEmailUser,
    isEmailVerified,
    currentUser,
    userInfo,
    setUserInfo,
    setUserHasPackage,
    userHasPackage,
    showImage,
    setShowImage,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
