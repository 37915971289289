import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import { useAuth } from "contexts/AuthProvider";
import { useEffect, useState } from "react";
import { BsCloudCheck } from "react-icons/bs";

const Storage = () => {
  const [userPlan, setUserPlan] = useState("");
  const { userInfo } = useAuth();

  useEffect(() => {
      setUserPlan(userInfo.subscriptionInfo.packageName);
  }, [userInfo]);

  return (
    <Card extra={"w-full flex flex-col items-center justify-center"}>
      <div className="flex flex-col items-center justify-center">
        <div className="text-3xl font-extrabold">
          <p className="text-blueSecondary">
            {userInfo?.subscriptionInfo?.status}
          </p>
          {/* Conditionally render user plan */}
          {userPlan && <p>{userPlan}</p>}
        </div>
      </div>
    </Card>
  );
};

export default Storage;
