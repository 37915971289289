import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { doSignInWithEmailAndPasswordPersistence } from "../../configs/auth";
import { useAuth } from "../../contexts/AuthProvider";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail, validatePasswordSignIn } from "../../utils/validators";
export default function SignIn() {
  const { userLoggedIn, setUserInfo, userHasPackage, setShowImage, userInfo } =
    useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [isError, setIsError] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false); // Sign In button loading state
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(true);
  useEffect(() => {
    setShowImage(true); // setup the image at right side of form
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // Login submit
  const onSubmit = async () => {
    setIsError("");
    setLoading(true);
    try {
      const userData = await doSignInWithEmailAndPasswordPersistence(
        email,
        password
      );

      setUserInfo({
        ...userInfo,
        emailVerified: userData.user.emailVerified,
      });

      if (userData.user.emailVerified) {
        setEmailError("");
      } else {
        setLoading(false);
        setEmailError("Your Email is not verified.");
      }

      // if (checked) {
      //   // signIn without Keep me logged In
      //   // const userCredential = await doSignInWithEmailAndPasswordPersistence(
      //   //   email,
      //   //   password
      //   // );
      // } else {
      //   // signIn with Keep me logged In
      //   // await doSignInWithEmailAndPassword(email, password);
      // }

      // Handle successful sign-in
    } catch (er) {
      setIsError(er);
      setLoading(false); // set Loading back to false if sign in failed
      if (er instanceof Error) {
        setIsError("sign in error : " + er.message); // Error message
      } else {
        console.error("Unknown error occurred:");
      }
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setIsError("");
    setEmail(emailValue);
    setEmailError(validateEmail(emailValue) || "");
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setPasswordError(validatePasswordSignIn(passwordValue) || "");
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      if (!isError && !passwordError && !emailError) {
        onSubmit();
      }
    }
  };

  // rout auth protection
  useEffect(() => {
    if (userLoggedIn) {
      if (userHasPackage === "Active") {
        // user logedin and has package
        navigate("/admin");
      } else if (userHasPackage !== "Active" && userHasPackage !== "") {
        // if user package data in unavailable
        navigate("/auth/price");
      }

      return;
    }
  }, []);

  return (
    <>
      <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
        {/* Sign in section */}
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Enter your email and password to sign in!
          </p>
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            value={email}
            onChange={handleEmailChange}
            onBlur={() => setEmailError(validateEmail(email))}
            onKeyPress={(e) => handleKeyPress(e)}
          />

          {isError.toString().includes("network") ||
          isError.toString().includes("credential") ||
          emailError ? (
            <div className="mb-3 h-4 text-xs text-red-500">
              {isError.toString().includes("network")
                ? "Check your network connection"
                : isError.toString().includes("credential")
                ? "You have entered an invalid email or password."
                : emailError}
            </div>
          ) : (
            <div className="mb-3 h-4 text-xs opacity-0">correct</div>
          )}

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onBlur={() => setPasswordError(validatePasswordSignIn(password))}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          {passwordError ? (
            <div className="mb-7 h-5 text-xs text-red-500">{passwordError}</div>
          ) : (
            <div className="mb-7 h-5 text-xs text-red-500 opacity-0"></div>
          )}
          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox checked={checked} onChange={handleChange} />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
            <Link
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              to="/auth/reset"
            >
              Forgot Password?
            </Link>
          </div>
          <button
            disabled={
              !email || !password || !!emailError || !!passwordError
                ? true
                : false
            }
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={onSubmit}
            onKeyDown={(e) => handleKeyPress(e)}
          >
            {/* loading spinner when user click Sign in */}
            {!loading ? (
              <span>Sign In</span>
            ) : (
              <span role="status">
                <svg
                  aria-hidden="true"
                  className="inline h-5 w-5 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </span>
            )}
          </button>
          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not registered yet?
            </span>
            <Link
              to="/auth/sign-up"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            >
              Create an account
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
