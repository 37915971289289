

const CampaignMap = () => {
  return (
    <div className='map_wrapper'>
      <iframe
      
      title="map"
        src="https://www.google.com/maps/d/embed?mid=1B1lAsUpYFg82Je7XN1_cxRPf1UU&hl=en_US&ehbc=2E312F"
        width="100%"
        height="600"
      ></iframe>
    </div>
  );
};

export default CampaignMap;

