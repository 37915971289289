// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";
import ForgotPassword from "views/auth/ForgotPassword";
import PricePage from "views/price/PricePage";

// Icon Imports
import {
  MdHome,
  MdBarChart,
  MdPerson,
  MdLock,
  MdDashboard,
} from "react-icons/md";
import { IoPricetag } from "react-icons/io5";

import Confirmation from "views/payment/Confirmation";
import QRCode from "views/admin/qrcode";
import CampaignDetail from "views/admin/campaign";
import { Outlet } from "react-router-dom";

const routes = [
  {
    name: "Main",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    private: true,
  },
  {
    name: "Actions",
    layout: "/admin",
    path: "qrcode",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <Outlet />,
    private: true,
    children: [
      {
        path: "",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <QRCode />,
      },
      {
        path: ":code",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <CampaignDetail />,
      }
    ]
  },

  {
    name: "Insights",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
    private: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    private: true,
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    private: false,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
    private: false,
  },
  {
    name: "Reset",
    layout: "/auth",
    path: "reset",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
    private: false,
  },
  {
    name: "Price",
    layout: "/auth",
    path: "price",
    icon: <IoPricetag className="h-6 w-6" />,
    component: <PricePage />,
    private: false,
  },
  {
    name: "Price",
    layout: "/admin",
    path: "price",
    icon: <IoPricetag className="h-6 w-6" />,
    component: <PricePage />,
    private: true,
  },
  {
    name: "Confirm",
    layout: "/auth",
    path: "confirm",
    icon: <IoPricetag className="h-6 w-6" />,
    component: <Confirmation />,
    private: false,
  },

  // {
  //   name: "Campaign Detail",
  //   layout: "/admin",
  //   path: "campaign/:id",
  //   icon: <MdDashboard className="h-6 w-6" />,
  //   component: <CampaignDetail />,
  //   private: true,
  // },
];
export default routes;
