import React from "react";
import Card from "components/card";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Pagination from "components/pagination";

type RowObj = {
  date: string;
  localTime: string;
  device: string;
  countryAndCity: string;
  gpsProperties: boolean;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Date</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("localTime", {
      id: "localTime",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Local Time
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("gpsProperties", {
      id: "gpsProperties",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          GPS Properties
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue() ? "Enable": "Disable"}
        </p>
      ),
    }),
    columnHelper.accessor("device", {
      id: "device",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Devices
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("countryAndCity", {
      id: "countryAndCity",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Country and City
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <div className="h-full overflow-hidden">
      <Card extra={"  h-fit px-6 overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          {/* scan location header */}
          <h2 className="m-2 mr-auto whitespace-nowrap text-2xl font-bold">
            Scan Location (GPS)
          </h2>
        </div>

        <div className=" overflow-x-hidden">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-3  pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <span className=" h-28 w-full"></span>
        <Pagination
          handleNextClick={() => console.log("next click")}
          handlePreviousClick={() => console.log("prev click")}
          page={0}
          pages={0}
          total={0}
          previousLoader={false}
          nextLoader={false}
        />
        <span className=" h-6 w-full"></span>
      </Card>
    </div>
  );
}
