// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const firebaseFunc = getFunctions(app);
// connectFunctionsEmulator(firebaseFunc, "127.0.0.1", 5001);
(async function () {
  // const payments = httpsCallable(firebaseFunc, 'payments');
  // const res = await payments()
  // console.log('-------------------',res);
  //   try {
  //     const d = await fetch(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL+'payments');
  //   const c = await d.json();
  //   console.log('000000000000000000',c);
  // } catch (error) {
  //     console.log('error',error);
  //   }
})();
// Initialize Cloud Firestore and get a reference to the service
// payments().then((result) => {
//   console.log(result);
// }).catch((error) => {
//   console.log(`error: ${JSON.stringify(error)}`);
// });
// console.log('fn---------------------', payments);

export { app, auth, db, firebaseConfig, firebaseFunc };
// export default firebaseConfig;
