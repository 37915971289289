import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CampaignSummary from "./components/CampaignSummary";
import ScanOverTime from "./components/ScanOverTime";
import ScanByDevice from "./components/ScanByDevice";
import CampaignMap from "./components/CampaignMap";
import TopLocation from "../campaign/components/TopLocations";
import ScanLocation from "./components/ScanLocation";
import { ArrowBackIcon, LinkIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useAuth } from "contexts/AuthProvider";
import { url } from "inspector";
type Campaign = {
  summary: {
    qrImage: string;
    createdAt: string;
    campaign: string;
    url: string;
    shortUrl: string;
    topLocation: string;
    topDevice: string;
    totalScans: number;
    uniqueScans: number;
    scans: number;
  };
  topLocation: [
    {
      location: string;
      scans: number;
      topDevice: string;
    }
  ];
  scanLocation: {
    date: string;
    localTime: string;
    device: string;
    countryAndCity: string;
    gpsProperties: boolean;
  }[];
  devices: {
    iOS: string;
    AndroidOS: string;
    PC: string;
  };
};

let campaign: Campaign = {
  summary: {
    qrImage: " ",
    createdAt: "",
    campaign: "",
    url: "",
    shortUrl: "",
    topLocation: "",
    topDevice: "",
    totalScans: 0,
    uniqueScans: 0,
    scans: 0,
  },
  topLocation: [
    {
      location: "",
      scans: 0,
      topDevice: "",
    },
  ],
  scanLocation: [
    {
      date: "",
      localTime: "",
      device: "",
      countryAndCity: "",
      gpsProperties: false,
    },
  ],
  devices: {
    iOS: "0",
    AndroidOS: "0",
    PC: "0",
  },
};

// type CampaignSummary = any;

const CampaignDetail: React.FC = () => {
  // const location = useLocation();
  const { currentUser } = useAuth();
  const { code: customerCampaignCode } = useParams();

  // const campaignSummary: CampaignSummary | undefined = location.state;
  // console.log('campaignSummary: ',campaignSummary);
  // console.log('code: ',campaignCode);

  const [loading, setLoading] = useState<boolean>(true);
  const [isData, setIsData] = useState<boolean>(false);

  const getQrCodeData = async (campaignCode: string) => {
    setLoading(true);
    await fetch(
      process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL + "getQrcodeByCode",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser?.accessToken,
        },
        body: JSON.stringify({ campaignCode }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json(); // Parse the JSON data
        }
        throw new Error("Network response was not ok.");
      })
      .then((response: any) => {
        const campaignData = response.data;

        campaign.summary.campaign = campaignData.campaign?.qrName;
        campaign.summary.createdAt = campaignData.campaign?.createdAt;
        campaign.summary.qrImage = campaignData.campaign?.qrImage;
        campaign.summary.url = campaignData.campaign?.redirectUrl;
        campaign.summary.shortUrl = campaignData.campaign?.shortUrl;

        if (campaignData?.data?.length > 0) {
          setIsData(true);

          // setting the campaign.summary object
          const deviceWithHighestCount = campaignData.device?.reduce(
            (prev: any, current: any) => {
              return prev.count > current.count ? prev : current;
            }
          );
          campaign.summary.topDevice =
            deviceWithHighestCount._id.device ?? "unknown";
          const cityWithHighestCount = campaignData.city.reduce(
            (prev: any, current: any) => {
              return prev.count > current.count ? prev : current;
            }
          );
          const countryWithHighestCount = campaignData.country.reduce(
            (prev: any, current: any) => {
              return prev.count > current.count ? prev : current;
            }
          );
          const topCountry = countryWithHighestCount._id.loc;
          const topCity = cityWithHighestCount._id.city;
          campaign.summary.topLocation = topCity.concat(topCountry);
          campaign.summary.totalScans = campaignData.totalScanByDate;
          campaign.summary.uniqueScans = campaignData.totalUniqueScan;
          campaign.summary.scans = campaignData.scans;

          // setting campaign TopLocation object { location , scans , topDevice}
          campaign.topLocation = campaignData.data.map((item: any) => ({
            location: item._id.city.concat(", ", item._id.loc),
            scans: item.count,
            topDevice: item._id.device ?? "unknown",
          }));

          // setting campaign ScanLocation object {data , localTime , device , countryAndCity , gpsProperties}
          campaign.scanLocation = campaignData.data.map(
            (item: { _id: { device: any; loc: any; city: any } }) => {
              return {
                date: "",
                localTime: "",
                device: item._id.device ?? "unknown",
                countryAndCity: `${item._id.city}, ${item._id.loc}`,
                gpsProperties: false,
              };
            }
          );
          // setting campaign Devices object { ios, androidOs , pc}
          campaignData.device.forEach(
            (deviceCount: { _id: { device: string }; count: any }) => {
              const device = deviceCount._id
                .device as keyof Campaign["devices"];
              const count = deviceCount.count;
              campaign.devices[device] = count.toString();
            }
          );
        } else {
          setIsData(false);
        }
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  function getSiteName(url: string) {
    // Remove protocol
    let siteName = url.replace(/(^\w+:|^)\/\//, "");
    // Remove www
    siteName = siteName.replace("www.", "");
    // Extract domain name
    siteName = siteName.split("/")[0];
    // Split .com
    const parts = siteName.split(".");
    // Remove the last part (.com)
    parts.pop();
    // Join remaining parts
    siteName = parts.join(".");
    // Capitalize first letter
    siteName = siteName.charAt(0).toUpperCase() + siteName.slice(1);
    return siteName;
  }

  const downloadImage = () => {
    try {
      const imageUrl = document.getElementById("QrImage")?.getAttribute("src");

      if (imageUrl) {
        // Fetch the image and create a download link
        fetch(imageUrl)
          .then((response) => {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "image.png");
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch((err) => {
            console.log("Error fetching image:", err);
          });
      } else {
        console.error("Image URL is empty");
      }
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const siteName = getSiteName(campaign.summary?.url);
  useEffect(() => {
    getQrCodeData(customerCampaignCode);
  }, []);

  return loading ? (
    <div className="mt-10 flex min-h-screen w-full items-center justify-center rounded-xl bg-white shadow-xl dark:bg-navy-800">
      <span role="status" className="-mt-10">
        <svg
          aria-hidden="true"
          className=" inline h-20 w-20 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </span>
    </div>
  ) : isData ? (
    <div>
      <span className="fixed top-20 z-50 ml-8 mt-5 ">
        <Link className="absolute right-[5%] top-[15%]" to={"/admin/qrcode"}>
          <ArrowBackIcon w={26} h={26} color={"gray"} />
        </Link>
      </span>
      <div className="mt-10 w-full flex-col items-center justify-center rounded-xl bg-white shadow-xl dark:bg-navy-800">
        <div className="delay-400 relative flex w-full flex-col rounded-xl text-gray-700">
          <CampaignSummary
            qrImage={campaign.summary?.qrImage}
            url={campaign.summary?.url}
            shortUrl={campaign.summary?.shortUrl}
            createdAt={campaign.summary?.createdAt}
            campaign={campaign.summary?.campaign}
            topLocation={campaign.summary?.topLocation}
            topDevice={campaign.summary?.topDevice}
            totalScans={campaign.summary?.totalScans}
            uniqueScans={campaign.summary?.uniqueScans}
          />

          <div className="delay-400 relative m-4 mt-5 flex flex-col text-gray-700">
            <ScanOverTime />
          </div>
          <div className="delay-400 relative m-4 mt-5 grid grid-cols-1 gap-5 text-gray-700 md:grid-cols-2">
            <ScanByDevice
              devices={{
                iOS: campaign.devices?.iOS,
                AndroidOS: campaign.devices?.AndroidOS,
                PC: campaign.devices?.PC,
              }}
            />
            <TopLocation tableData={campaign?.topLocation} />
          </div>
          <div className="delay-400 relative m-4 mt-5 flex flex-col text-gray-700">
            <CampaignMap />
          </div>
          <div className="delay-400 relative m-4 mt-5 flex flex-col text-gray-700">
            <ScanLocation tableData={campaign?.scanLocation} />
          </div>
        </div>
      </div>
    </div>
  ) : !loading && (
    <div className=" flex h-[100vh] w-full flex-col gap-6">
      <CampaignSummary
        qrImage={campaign.summary?.qrImage}
        url={campaign.summary?.url}
        shortUrl={campaign.summary?.shortUrl}
        createdAt={campaign.summary?.createdAt}
        campaign={campaign.summary?.campaign}
        topLocation={campaign.summary?.topLocation}
        topDevice={campaign.summary?.topDevice}
        totalScans={campaign.summary?.totalScans}
        uniqueScans={campaign.summary?.uniqueScans}
      />
      {/* <div className="flex w-full flex-col rounded-lg px-6 py-7">
        {(!loading || !isData ) && (
          <div className="mt-5 flex w-full flex-col flex-wrap items-center justify-evenly gap-6  3xl:flex-nowrap">
            <h1 className="mb-4 text-2xl font-bold text-blueSecondary dark:text-white">
              <strong></strong> {campaign.summary?.campaign}
            </h1>
            <div className="flex flex-col justify-between bg-white p-6 rounded-xl my-3">
              <div className="flex w-full flex-wrap items-center justify-center gap-4 p-4">
                <img
                  src={campaign.summary?.qrImage}
                  id="QrImage"
                  alt="QR Code"
                  className="mb-4 h-44 w-44"
                />
                <div>
                  <p className="mb-2 flex flex-col text-gray-800 dark:text-white">
                    <strong className="text-2xl">{siteName}</strong>
                    <strong>URL</strong>
                  </p>
                  <p className="mb-2 flex gap-1 text-gray-800 dark:text-white">
                    <LinkIcon w={20} h={20} className="mr-1" />{" "}
                    {campaign.summary?.url}
                  </p>
                  <p className="mb-2 flex gap-1 text-gray-800 dark:text-white">
                    <strong>Created At: </strong> {campaign.summary?.createdAt.slice(0,10)}
                  </p>
                </div>
              </div>
            </div>
            <div className="item-center flex min-w-fit flex-col justify-center gap-4 p-4">
              <div className="flex flex-wrap justify-center gap-3 xl:flex-nowrap">
                <button
                  className="rounded-xl bg-blueSecondary px-5 py-3 text-base font-medium text-white transition duration-200 hover:opacity-90"
                  onClick={downloadImage}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        )}
      </div> */}
      <span className="item-start fixed !z-40 ml-8 mt-5 md:top-24 ">
        <Link className="absolute right-[5%] top-[15%]  " to={"/admin/qrcode"}>
          <ArrowBackIcon w={26} h={26} color={"gray"} />
        </Link>
      </span>
      <p className="w-full text-center text-2xl">No campaign data available</p>
    </div>
  );
};

export default CampaignDetail;
