/* eslint-disable */

import { HiLogout, HiX } from "react-icons/hi";
import Links from "./components/Links";

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes";
import { Link, useNavigate } from "react-router-dom";
import { doSignOut } from "configs/auth";
import { background, color } from "@chakra-ui/system";

import logoDark from "../../assets/img/logo/Adaptix Logo v2.1_dark.png";
import logoWhite from "../../assets/img/logo/Adaptix Logo v2.1_white.png";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const navigate = useNavigate();
  const handelLogout = async () => {
    try {
      localStorage.clear();
      await doSignOut();
      navigate("/auth/sign-in");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  const { open, onClose } = props;
  return (
    <div
      className={`sm:none  duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute  right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] ml-4 mr-24 mt-2 flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:hidden dark:text-white">
          <img className="max-h-14" src={logoDark} />
        </div>
        <div className="ml-1 mt-1 hidden h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:block dark:text-white">
          <img className="max-h-14" src={logoWhite} />
        </div>
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
        <Link to="/your-route" onClick={handelLogout}>
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li className="my-[3px] flex cursor-pointer items-center px-8">
              <span
                style={{ width: "24px", height: "24px", display: "flex" }}
                className="flex items-center justify-center  font-medium text-gray-600"
              >
                <HiLogout />
              </span>
              <p className={`leading-1 ml-4 flex font-medium text-gray-600`}>
                Log Out
              </p>
            </li>
          </div>
        </Link>
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center"></div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
