import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck";
import tableDataComplex from "./variables/tableDataComplex";
import { useAuth } from "../../../contexts/AuthProvider";
import { Navigate } from "react-router-dom";

const Dashboard = () => {
  const { userLoggedIn } = useAuth();
  if (!userLoggedIn) {
    return (
      <div>
        <Navigate to={"/auth/sign-in"} replace={true} />
      </div>
    );
  }
  return (
    <>
      {/* {!userLoggedIn && (<Navigate to={'/auth/sign-in'} replace={true} />)} */}
      <div>
        {/* Card widget */}

        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Visits"}
            subtitle={"3405"}
          />
          <Widget
            icon={<IoDocuments className="h-6 w-6" />}
            title={"Scans this month"}
            subtitle={"642"}
          />
          <Widget
            icon={<MdDashboard className="h-7 w-7" />}
            title={"Nodes Created"}
            subtitle={"12"}
          />
          {/* <Widget
            icon={<MdDashboard className="h-6 w-6" />}
            title={"Your Balance"}
            subtitle={"$1,000"}
          />
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"New Tasks"}
            subtitle={"145"}
          />
          <Widget
            icon={<IoMdHome className="h-6 w-6" />}
            title={"Total Projects"}
            subtitle={"$2433"}
          /> */}
        </div>

        {/* Charts */}

        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <TotalSpent />
          <WeeklyRevenue />
        </div>

        {/* Tables & Charts */}

        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
          {/* Check Table */}
          <div>
            <CheckTable tableData={tableDataCheck} />
          </div>

          {/* Traffic chart & Pie Chart */}

          <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
            <DailyTraffic />
            <PieChartCard />
          </div>

          {/* Complex Table , Task & Calendar */}

          <ComplexTable tableData={tableDataComplex} />

          {/* Task chart & Calendar */}

          <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
            <TaskCard />
            <div className="grid grid-cols-1 rounded-[20px]">
              <MiniCalendar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
