import React, { useState } from "react";
import { useAuth } from "contexts/AuthProvider";

// props interface
interface PriceProps {
  packageTime: string;
  packageId: string;
  data: {
    adaptiveNodes: string;
    features: string[];
    id: string;
    imageUrl: string;
    label: string;
    monthly: string;
    mostPopular: boolean;
    name: string;
    trialPossible: boolean;
    yearly: string;
  };
}

function Price({ packageTime, packageId, data }: PriceProps) {
  const { userInfo, currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false); // packages get started button loading state

  const selectedPackage = () => {
    // checks which package is current package
    if (
      packageId === userInfo.subscriptionInfo.packageId &&
      packageTime === userInfo.subscriptionInfo.duration
    ) {
      return "Your Package";
    } else {
      return "Get Started";
    }
  };

  function getCurrentDate() {
    // get package current Date
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    return currentDate;
  }
  function getEndDate() {
    // colculate package end Date
    const date = new Date();
    if (packageTime === "monthly") {
      // setting monthly package
      let day = date.getDate();
      let month = date.getMonth() + 2;
      let year = date.getFullYear();
      let endDate = `${year}-${month}-${day}`;
      return endDate;
    }
    if (packageTime === "yearly") {
      // setting yearly package
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear() + 1;
      let endDate = `${year}-${month}-${day}`;
      return endDate;
    }
  }

  // adding package to subscription node of firebase database through cloud functions
  async function addNewPackage(params: any) {
    try {
      const url =
        process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL +
        "updateSubscription";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser?.accessToken,
        },
        body: JSON.stringify(params),
      });
      const responseData = await response.json();
      console.log("Price Document successfully written!", responseData);

      if (packageTime === "monthly") {
        if (params.packagePrice === "9") {
          window.open(
            "https://app.hubspot.com/payments/FzZF9sPGQdc?referrer=PAYMENT_LINK",
            "_self"
          );
        }
        if (params.packagePrice === "29") {
          window.open(
            "https://app.hubspot.com/payments/zVSY2SQ7s?referrer=PAYMENT_LINK",
            "_self"
          );
        }
        if (params.packagePrice === "99") {
          window.open(
            "https://app.hubspot.com/payments/fpdDykpCFGKYJ?referrer=PAYMENT_LINK",
            "_self"
          );
        }
      } else if (packageTime === "yearly") {
        if (params.packagePrice === "99") {
          window.open(
            "https://app.hubspot.com/payments/XcrvXFMwRKPFm?referrer=PAYMENT_LINK",
            "_self"
          );
        }
        if (params.packagePrice === "329") {
          window.open(
            "https://app.hubspot.com/payments/sSxdCWMQVcwyNzW?referrer=PAYMENT_LINK",
            "_self"
          );
        }
        if (params.packagePrice === "1089") {
          window.open(
            "https://app.hubspot.com/payments/nZsWG4hWxghX67?referrer=PAYMENT_LINK",
            "_self"
          );
        }
      }
    } catch (error) {
      setLoading(false); // set loader to false if error occures
      console.error("Error writing document:", error);
    }
  }

  // user subscription info setup
  function buyPackage(): void {
    setLoading(true);
    // setting packages time monthly/yearly
    let tempPrice = "";
    if (packageTime === "monthly") {
      tempPrice = data.monthly;
    } else {
      tempPrice = data.yearly;
    }

    // setting subscription info of user
    const subscription = {
      packageId: data.id,
      userId: userInfo.userId,
      duration: packageTime,
      startDate: getCurrentDate(),
      endDate: getEndDate(),
      totalCredits: data.adaptiveNodes,
      availableCredits: data.adaptiveNodes,
      isTrial: data.trialPossible,
      autoRenew: false,
      history: new Array(),
      status: "pending",
      packagePrice: tempPrice,
      packageName: data.name,
    };

    //adds package to user
    addNewPackage(subscription)
      .then(() => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  return (
    <div
      className={`mb-10 flex flex-col items-start rounded-xl p-10 ${
        data.mostPopular ? "border-2 border-green-500" : ""
      }`}
      style={{
        position: "relative",
        backgroundColor: data.mostPopular === true ? "#232226" : "#ECEBF5",
        color: data.mostPopular === true ? "white" : "rgb(22, 21, 25)",
      }}
    >
      {/* packages */}
      <div
        className="flex"
        style={{
          display: data.mostPopular ? "flex" : "none",
          position: "absolute",
          left: "0px",
          alignItems: "center",
          justifyContent: "center",
          right: "0px",
          top: "-25px",
        }}
      >
        <span className="rounded-md bg-green-500 px-3 py-2 text-xl font-bold">
          Most Popular
        </span>
      </div>
      <h5 className="mb-6 text-xl font-bold">{data.name}</h5>
      <div className="pricing-table mb-6 flex flex-col items-start">
        <span className="mb-2 text-5xl	font-bold lg:mb-5  lg:text-7xl">
          {/* Package Price ---> monthly/yearly */}
          {packageTime === "monthly" ? `$${data.monthly}` : `$${data.yearly}`}
        </span>
        <span className="rounded-md bg-appcolor-100 px-2 py-1 font-medium hover:bg-appcolor-200">
          {/* monthly/yearly package */}
          {packageTime === "monthly" ? "Monthly" : "Yearly"}
        </span>
      </div>
      <div className="mb-4">
        {/* package Features with mark svg */}
        {data.features.map((feature, index) => (
          <div key={index} className="mb-2 font-normal">
            <span
              style={{
                display: "inline-block",
                marginRight: "9px",
                marginBottom: "-5px",
              }}
            >
              <svg
                className="default"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="green"
              >
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
              </svg>
            </span>
            <span className="text-lg " style={{ lineHeight: "20px" }}>
              {feature}
            </span>
          </div>
        ))}
      </div>
      {/* get startes button */}
      <div className="mt-5 mt-auto">
        <button
          onClick={buyPackage}
          className={`text-bold mt-5 min-w-28 whitespace-nowrap rounded-md px-3 py-1.5 transition-all transition-all hover:bg-green-500 ${
            data.mostPopular === true ? "bg-gray-50 " : "bg-gray-800"
          }`}
          style={{
            color: data.mostPopular === true ? "black" : "#ffffff",
            fontWeight: "600",
            marginTop: "auto",
          }}
        >
          {/* loading spinner when user click Sign in */}
          {!loading ? (
            <span>{selectedPackage()}</span>
          ) : (
            <span role="status">
              <svg
                aria-hidden="true"
                className="inline h-5 w-5 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </span>
          )}
        </button>
      </div>
    </div>
  );
}

export default Price;
