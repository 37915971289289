import BarChart from "components/charts/BarChart";
import Card from "components/card";

// props interface
interface MyComponentProps {
  devices: {
    iOS: string;
    AndroidOS: string;
    PC: string;
  };
}
const ScanByDevice: React.FC<MyComponentProps> = ({ devices }) => {
  const { iOS, AndroidOS, PC } = devices;

  //   graph data
  const barChartDataDailyTraffic = [
    {
      name: "Devices",
      data: [PC, AndroidOS, iOS],
    },
  ];

  //   graph setup
  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "",
        backgroundColor: "#000000",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: "",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: ["PC", "AndroidOS", "iOS"],
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#4318FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(67, 24, 255, 1)",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: "40px",
      },
    },
  };
  return (
    <div>
      <Card extra="pb-7 h-full p-[20px]">
        <div className="my-2 flex w-full items-center gap-4">
          {/* graph heading */}
          <h2 className="m-2 mr-auto text-2xl font-bold">Scans By Device</h2>
        </div>

        <div className="h-[300px] w-full pb-0 pt-10">
          {/* graph */}
          <BarChart
            chartData={barChartDataDailyTraffic}
            chartOptions={barChartOptionsDailyTraffic}
          />
        </div>
      </Card>
    </div>
  );
};

export default ScanByDevice;
