import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/auth/Adaptix.png";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { useAuth } from "../../contexts/AuthProvider";

export default function Auth() {
  const { userLoggedIn, showImage, userHasPackage } = useAuth();
  // route protection
  if (userLoggedIn && userHasPackage === "Active") {
    return (
      <div>
        <Navigate to={"/admin"} replace={true} />
      </div>
    );
  }
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (!prop.private) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            {/* updated styles for the responsive of Price packages page */}
            <div
              className={`mx-auto flex h-[100vh] min-h-full w-full flex-col justify-start ${
                userLoggedIn
                  ? "px-7"
                  : "lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px] "
              }`}
            >
              <div
                className={`mb-auto flex flex-col pl-5 pr-5 xl:max-w-full xl:pl-12 ${
                  userLoggedIn ? "" : "md:pl-12 md:pr-12 lg:max-w-[48%]"
                }`}
              >
                {/* <Link to="/admin" className="mt-0 w-max lg:pt-10">
                  <div className="mx-auto flex h-fit w-fit items-center hover:cursor-pointer">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                        fill="#A3AED0"
                      />
                    </svg>
                    <p className="ml-3 text-sm text-gray-600">
                      Back to Dashboard
                    </p>
                  </div>
                </Link> */}
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
                {/* show sign in img just in case user isn't loged in (not showing in auth/price) */}
                {showImage && (
                  <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                    <div
                      className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                      style={{ backgroundImage: `url(${authImg})` }}
                    />
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
